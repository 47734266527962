.blog-inner {
	background-image: url("../img/blog2.png") !important;
}
.firstSection {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	margin-bottom: 40px;
}
.firstSection .headingH4 {
	color: #1d1d1d;
	font-family: "Monument Extended", sans-serif;
}
.search input {
	border: none;
	box-shadow: 1px 1px 5px #ccc;
	border-radius: 50px;
	padding: 14px 20px;
	font-family: "Montserrat";
	font-size: 14px;
}
.search input:focus {
	outline: none;
}
.firstSection h2.headingH4 {
	flex: 2 1;
	font-size: 45px;
}
.pageCount button {
	font-family: "Poppins";
	font-size: 13px;
}
.firstSection .search {
	flex: 1;
	display: flex;
	position: relative;
}
.firstSection .search input {
	width: 100%;
}
.firstSection .search button.searchIcon {
	border: 0;
	background: #43f22c;
	width: 45px;
	height: 45px;
	border-radius: 50%;
	line-height: 30px;
	position: absolute;
	right: 8px;
	top: 8px;
}
.firstSection .search button.searchIcon img {
	width: 20px;
}

.card_Item {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 70px;
	margin: 60px 0;
}
.card {
	border: none;
	box-shadow: 1px 1px 5px #e7eaf3;
	margin-bottom: 50px;
}
.card .card-body {
	padding-bottom: 50px;
}
.card .card-body h5.card-title {
	font-family: "poppins";
	font-weight: 600;
	margin: 0;
}
.card .card-body p.card-text {
	font-family: "Poppins";
	font-size: 14px;
	line-height: 24px;
	margin: 0;
}
.card .card-body a.btn.btn-primary {
	font-family: "Poppins";
	font-weight: 600;
	padding: 11px 35px;
}
.pagination {
	margin-bottom: 20px;
}

.pageCount .btn1 {
	border: none;
	background: #1f1f1f;
	color: #f5f5f5;
	padding: 10px 20px;
	display: inline-block;
	margin: 0 4px;
}

.pageCount .btn3 {
	border: none;
	background: #43f22c;
	color: #f5f5f5;
	padding: 10px 20px;
	display: inline-block;
	margin: 0 4px;
}

.pageCount .btn2 {
	background-color: transparent;
	border: 2px solid #43f22c;
	padding: 8px 20px 8px 20px;
	margin: 0 4px;
}
.pageCount .btn2 i {
	position: relative;
	color: #43f22c;
	font-size: 20px;
	font-weight: 700;
	top: 1.5px;
	left: 6px;
}
.card .img_box {
	position: relative;
}
.card .img_box .date {
	position: absolute;
	top: 10px;
	left: 15px;
}
.card .img_box .date p {
	color: #000;
	font-family: "Poppins";
	font-size: 13px;
	background: #baffb1;
	padding: 2px 12px;
}
