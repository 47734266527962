/* Profile Css Start Here */
.profile_Sec {
	padding: 50px 0;
}
.profile_Sec .sidebar {
	position: relative;
	background: #f5f5f5;
	padding: 30px 20px;
	height: 100%;
}
.profile_Sec .sidebar .heading {
	padding-bottom: 15px;
}
.profile_Sec .sidebar .heading h4 {
	font-family: "Poppins";
	font-size: 16px;
	color: #989898;
}
.profile_Sec .sidebar ul.navs {
	padding: 0;
	list-style: none;
}
.profile_Sec .sidebar ul.navs li a {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #d1d1d154;
	padding: 10px 0px;
	text-decoration: none;
}
.profile_Sec .sidebar ul.navs li span {
	color: #656565;
	font-size: 14px;
	font-family: "Poppins";
	font-weight: 500;
}
.profile_Sec .sidebar ul.navs li img.img-fluid {
	width: 15px;
}
.profile_Sec .sidebar .logout-btn {
	position: absolute;
	bottom: 20px;
	left: 20px;
}
.profile_Sec .sidebar .logout-btn button.btn {
	background: transparent;
	border: 0;
	padding: 0;
	color: #000;
	font-family: "Poppins";
	font-weight: 500;
}
.profile_Sec .right-side .head {
	display: flex;
	align-items: flex-start;
	justify-content: space-around;
}
.profile_Sec .right-side .head .edit-btn a {
	color: #2382ff;
	font-family: "Poppins";
}
.profile_Sec .right-side .head .name-image {
	display: flex;
	align-items: flex-start;
	padding-right: 40px;
}
.profile_Sec .right-side .head .name-image figure img {
	max-width: 100px;
	margin-right: 20px;
}
.profile_Sec .right-side .head .name-image .name h4 {
	font-family: "Poppins";
	color: #4d4d4d;
	font-weight: 600;
}
.profile_Sec .right-side .head .name-image p {
	font-size: 15px;
	font-family: "Poppins";
	color: #8a8a8a;
	line-height: 23px;
}
.profile_Sec .right-side .title {
	padding-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.profile_Sec .right-side .title button {
	background: #535353;
	border: 0;
	color: #fff;
	font-family: "Poppins";
	font-size: 14px;
	font-weight: 500;
	padding: 10px 20px;
	border-radius: 5px;
}
.profile_Sec .right-side .title h3 {
	font-size: 32px;
	font-family: "Poppins";
	font-weight: 700;
}
.profile_Sec .right-side .user-detail {
	padding-top: 10px;
}
.profile_Sec .right-side .user-detail h4 {
	font-size: 16px;
	color: #989898;
	font-family: "Poppins";
	padding-bottom: 5px;
}
.profile_Sec .right-side .user-detail table.table tr {
	border-top: 1px solid #d9d9d9 !important;
}
.profile_Sec .right-side .user-detail table.table tr td {
	border: 0;
	vertical-align: middle;
	padding: 15px;
}
.profile_Sec .right-side .user-detail .table-responsive {
	border: 1px solid #ddd;
	border-radius: 5px;
	padding: 10px 10px;
}
.profile_Sec .right-side .user-detail .table-responsive table.table th {
	vertical-align: middle;
	color: #111111;
	font-family: "Poppins";
	font-size: 14px;
	font-weight: 500;
	padding: 15px;
	border-top: 0;
}
.profile_Sec
	.right-side
	.user-detail
	.table-responsive
	table.table
	.form-control {
	color: #8a8a8a;
	font-family: "Poppins";
	font-weight: 400;
	font-size: 14px;
	padding: 0;
	border: 0;
	outline: none;
	box-shadow: none;
}
.profile_Sec .right-side .user-detail .table-responsive table.table a {
	font-family: "Poppins";
	font-weight: 400;
	font-size: 14px;
}
.profile_Sec
	.right-side
	.user-detail
	.table-responsive
	table.table
	td:last-child {
	text-align: end;
}
.profile_Sec .right-side .user-detail table.table tr:first-child {
	border: 0 !important;
}
.profile_Sec .right-side .user-detail .table-responsive table {
	margin: 0;
}

/* Chat Css Start Here */
.profile_Sec .right-side .peoples {
	background: #f3f3f3;
	padding: 20px 0px;
}
.profile_Sec .right-side .peoples .header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px 20px;
}
.profile_Sec .right-side .peoples .header h3 {
	color: #2b2b2b;
	font-size: 20px;
	font-weight: 600;
}
.profile_Sec .right-side .peoples .chat-box {
	display: flex;
	align-items: flex-start;
	padding: 15px 20px;
	margin-bottom: 3px;
	cursor: pointer;
}
.profile_Sec .right-side .peoples .chat-box.active {
	background: #fff;
}
.profile_Sec .right-side .peoples .chat-box h4.name {
	font-size: 14px;
	color: #393939;
	font-family: "Poppins";
	font-weight: 600;
	margin: 0 0 2px;
}
.profile_Sec .right-side .peoples .chat-box p {
	font-size: 13px;
	font-family: "Poppins";
	color: #b2b2b2;
	font-weight: 500;
	margin: 0;
}
.profile_Sec .right-side .peoples .chat-box .message {
	padding: 0 0px 0 12px;
}
.profile_Sec .right-side .peoples .chat-box figure img {
	width: 40px;
}
.profile_Sec .right-side .peoples .chat-box figure {
	margin: 0;
}
.profile_Sec .right-side .peoples .all-chats {
	height: 500px;
	overflow-y: auto;
}
.profile_Sec .right-side .peoples .all-chats::-webkit-scrollbar {
	width: 0.2em;
}
.profile_Sec .right-side .peoples .all-chats::-webkit-scrollbar-thumb {
	background-color: #ddd;
}
.profile_Sec .chatting-box {
	height: 100%;
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	padding: 0 10px;
	position: relative;
}
.profile_Sec .chatting-box .header {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #f5f5f5;
	padding: 12px 40px 5px;
}
.profile_Sec .chatting-box .header .name h4 {
	color: #393939;
	font-size: 14px;
	font-family: "Poppins";
	margin: 0 0 2px;
}
.profile_Sec .chatting-box .header .name p {
	color: #b2b2b2;
	font-family: "Poppins";
	font-size: 13px;
	margin: 0;
}
.profile_Sec .chatting-box .header figure {
	margin: 0 10px 0 0px;
}
.profile_Sec .chatting-box .send-box {
	display: flex;
	position: absolute;
	bottom: 0;
	width: 100%;
	border-top: 1px solid #ddd;
	left: 0;
	padding: 10px 10px 10px;
}
.profile_Sec .chatting-box .send-box .form-control {
	border-radius: 0;
	border: 0;
	box-shadow: none;
	outline: none;
	color: #b2b2b2;
	font-family: "Poppins";
	font-size: 13px;
}
.profile_Sec .chatting-box .send-box .form-control::placeholder {
	color: #b2b2b2;
}
.profile_Sec .chatting-box .send-box button.btn {
	background: #4594f9;
	border: 0;
	color: #ffff;
	font-family: "Poppins";
	padding: 9px 40px;
}
.profile_Sec .chatting-box {
	height: 100%;
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	padding: 0 10px;
	position: relative;
}
.profile_Sec .chatting-box .header {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #f5f5f5;
	padding: 12px 40px 5px;
}
.profile_Sec .chatting-box .header .name h4 {
	color: #393939;
	font-size: 14px;
	font-family: "Poppins";
	margin: 0 0 2px;
}
.profile_Sec .chatting-box .header .name p {
	color: #b2b2b2;
	font-family: "Poppins";
	font-size: 13px;
	margin: 0;
}
.profile_Sec .chatting-box .header figure {
	margin: 0 10px 0 0px;
}
.profile_Sec .chatting-box .send-box {
	display: flex;
	position: absolute;
	bottom: 0;
	width: 100%;
	border-top: 1px solid #ddd;
	left: 0;
	padding: 10px 10px 10px;
}
.profile_Sec .chatting-box .send-box .form-control {
	border-radius: 0;
	border: 0;
	box-shadow: none;
	outline: none;
	color: #b2b2b2;
	font-family: "Poppins";
	font-size: 13px;
}
.profile_Sec .chatting-box .send-box .form-control::placeholder {
	color: #b2b2b2;
}
.profile_Sec .chatting-box .send-box button.btn {
	background: #4594f9;
	border: 0;
	color: #ffff;
	font-family: "Poppins";
	padding: 9px 40px;
}
.profile_Sec .chatting-box .messages {
	padding: 20px 10px;
	height: 450px;
	background: #fff;
	overflow-y: scroll;
}
.profile_Sec .chatting-box .messages::-webkit-scrollbar {
	width: 0em;
}
.profile_Sec .chatting-box .messages .messenger {
	display: flex;
	align-items: flex-end;
	max-width: 90%;
	margin-bottom: 40px;
}
.profile_Sec .chatting-box .messages .scnd-side {
	margin: 0px 0px 40px auto;
}
.profile_Sec .chatting-box .messages .messenger img.img-fluid {
	max-width: 90px;
}
.profile_Sec .chatting-box .messages .messenger .meesage {
	background: #f8f8f8;
	padding: 10px 10px;
	font-family: "Poppins";
	color: #929292;
	border-radius: 6px 6px 6px 0;
	margin: 0 10px;
}
.profile_Sec .chatting-box .messages .messenger .meesage p {
	font-size: 13px;
	margin: 0;
	color: #929292;
}
.profile_Sec .chatting-box .messages .messenger figure {
	margin: 0;
}
.profile_Sec .chatting-box .messages .messenger .time p {
	margin: 0;
	width: 60px;
	color: #232323;
	font-family: "Poppins";
	font-size: 13px;
}
/* Chat Css End Here */

/* My Adds Css Start Here */
.profile_Sec .right-side .title button {
	background: #535353;
	border: 0;
	color: #fff;
	font-family: "Poppins";
	font-size: 14px;
	font-weight: 500;
	padding: 10px 20px;
	border-radius: 5px;
}
.feature-head .tag {
	display: flex;
	align-items: center;
	background: #43f22c;
	padding: 3px 10px;
	border-radius: 5px;
}
.feature-head .tag span {
	font-size: 9px;
	color: #111111;
	font-family: "Poppins";
	font-weight: 600;
}
.feature-head .tag img {
	width: 13px;
	filter: brightness(0) invert(1);
}
.profile_Sec .postAdd {
	height: 100%;
	border: 0;
	background: transparent;
	padding: 0;
	width: 100%;
}
.profile_Sec .post-add {
	background: #f2f2f2;
	height: 100%;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	cursor: pointer;
	box-shadow: none;
	outline: none;
}
.profile_Sec .post-add h4 {
	color: #444444;
	font-family: "Montserrat";
	text-transform: uppercase;
	font-size: 20px;
	font-weight: 700;
}
.profile_Sec .post-add p {
	font-size: 14px;
	margin: 0;
	color: #929292;
	font-family: "Poppins";
	font-weight: 500;
}
/* My Adds Css End Here */

/* Add Post Modal Css Start Here */
.post-add-modal .modal-open .modal {
	background: #ffffff33;
	backdrop-filter: blur(13px);
	opacity: 1 !important;
}
.post-add-modal .modal-content {
	border-radius: 0;
	padding: 40px 0;
}
.post-add-modal .modal-content .modal-header {
	justify-content: center;
	border: 0;
}
.post-add-modal .modal-content .modal-header img.img-fluid {
	width: 30px;
}
.post-add-modal .modal-content .modal-header span {
	color: #1a1a1a;
	font-family: "Poppins";
	font-weight: 800;
	font-size: 28px;
	padding: 0 10px;
}
.post-add-modal .modal-content .button-group {
	text-align: center;
}
.post-add-modal .modal-content .button-group button.btn {
	background: #43f22c;
	border-radius: 0;
	font-size: 16px;
	font-family: "Poppins";
	font-weight: 600;
	padding: 8px 80px;
	color: #000;
	margin-top: 40px;
}
.post-add-modal .modal-content ul {
	padding: 0;
	list-style: none;
	max-width: 250px;
	margin: 0px auto;
}
.post-add-modal .modal-content ul li {
	text-align: left;
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.post-add-modal .modal-content ul li ruby {
	color: #444444;
	font-family: "Poppins";
	font-size: 14px;
}
.post-add-modal .modal-content ul li span.price {
	color: #000;
	font-family: "Poppins";
	font-size: 16px;
	font-weight: 600;
	padding-left: 15px;
	text-align: center;
	border-left: 1px solid #ddd;
	padding: 8px 0 8px 13px;
}
.post-add-modal .modal-content ul li label {
	margin: 0;
}
.post-add-modal .modal-content ul li > span label {
	position: relative;
	padding-left: 15px;
}
.post-add-modal .modal-content ul li span.dot {
	width: 11px;
	height: 11px;
	position: absolute;
	background: #fff;
	left: -5px;
	top: 7px;
	border-radius: 50%;
	border: 1px solid #fff;
	box-shadow: 0px 0px 0px 1px #43f22c;
}
.post-add-modal .modal-content ul li input:checked ~ span.checkmark span.dot {
	background: #43f22c;
}
/* Add Post Modal Css End Here */

/* Bidding Css Start Here */
.profile_Sec table.table th {
	padding: 0 !important;
	border: 0 !important;
}
.profile_Sec table.table td {
	border: 0;
}
.profile_Sec table.table tr {
	border: 0;
}
.profile_Sec table.table th {
	padding: 0 !important;
	color: #333333;
	border: 0 !important;
	font-family: "Poppins";
	font-weight: 600;
	padding-bottom: 6px !important;
	padding: 5px 15px !important;
	width: 25%;
}
.profile_Sec table.table td {
	border: 0;
}
.profile_Sec table.table tr {
	border: 0;
}
.profile_Sec table.table tbody {
	border: 0;
}
.profile_Sec table.table tbody {
	border: 1px solid #ddd;
}
.profile_Sec table.table tbody tr td {
	vertical-align: middle;
	padding: 6px 15px;
	width: 25%;
}
.profile_Sec table.table tbody tr td:first-child {
	color: #5e5e5e;
	font-family: "Poppins";
	font-weight: 500;
}
.profile_Sec table.table tbody tr td:nth-child(2) {
	color: #8a8a8a;
	font-family: "Poppins";
}
.profile_Sec table.table tbody tr td:nth-child(3) {
	font-family: "Poppins";
	color: #8a8a8a;
	font-style: italic;
}
.profile_Sec table.table td button.btn {
	background: #5e5e5e;
	border: 0;
	color: #fff;
	font-size: 13px;
	font-family: "Poppins";
	padding: 10px 35px;
	margin: 0;
}
.profile_Sec table.table tbody tr td {
	vertical-align: middle;
	padding: 6px 15px;
	width: 25%;
	border-bottom: 1px solid #ddd;
}
.profile-detail .bidding-list .button-group {
	padding-top: 60px;
	text-align: end;
}
.profile-detail .bidding-list .button-group button.lnk {
	border: 0;
	background: transparent;
	color: #333333;
	font-family: "Poppins";
	font-weight: 500;
	font-size: 15px;
	box-shadow: none;
	outline: none;
}
.profile_Sec table.table tbody tr .ev-div {
	display: flex;
	align-items: center;
}
.profile_Sec table.table tbody tr .ev-div figure img {
	max-width: 80px;
}
.profile_Sec table.table tbody tr .ev-div p {
	width: 180px;
	margin: 0;
	color: #8a8a8a;
	font-size: 14px;
	font-style: italic;
	margin-left: 10px;
}
.profile_Sec table.table tbody tr .ev-div figure {
	margin: 0;
}
.profile_Sec table.table tbody tr td {
	font-family: "Poppins";
	color: #8a8a8a;
}
.profile_Sec table.table tbody tr td button.btn {
	background: #43f22c;
	padding: 11px 35px;
	font-weight: 500;
}
/* Bidding Css End Here */

/* Profile Css End Here */
