.PostVehicle {
	background-image: url("../img/post1.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	padding: 100px 0px;
}
.PostVehicle h4 {
	font-family: "Poppins";
	text-align: center;
	font-size: 32px;
	font-weight: 700;
	color: #1a1a1a;
}
/* .PostVehicle .sc-dkzDqf.fVfcRr {
	display: none;
} */
.PostVehicle label svg {
	display: none;
}
.PostVehicle .kiHXWb {
	width: auto;
	min-width: 100%;
	height: 153px;
	border: 0px;
	background-color: #fff;
	background-image: url("../img/Icon\ ionic-md-photos.png");
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 13px;
}
.PostVehicle .fVfcRr .file-types {
	display: none;
}
.PostVehicle .fVfcRr {
	position: absolute;
	bottom: 3px;
	text-align: center;
}
.PostVehicle form {
	margin-top: 37px;
}
.PostVehicle form .form-control {
	padding: 10px;
	border: 0px;
	font-family: "Montserrat";
	font-size: 16px;
	color: #808080;
}
.PostVehicle h5 {
	color: #1e1e1e;
	font-family: "Montserrat";
	font-size: 20px;
	font-weight: 600;
}
.PostVehicle .form-heading-wrapper {
	padding: 15px 0px;
}
.PostVehicle label {
	color: #808080;
	font-family: "Montserrat";
	font-size: 16px;
	font-weight: 400;
}
.PostVehicle .file-wrapper {
	padding: 20px 0px;
}
.PostVehicle .form-check-input:checked {
	background: transparent;
	border: 1px solid;
	box-shadow: none;
}
.PostVehicle .form-check-input:checked[type="radio"]::after {
	content: "";
	display: block;
	position: absolute;
	width: 8px;
	height: 8px;
	background: black;
	top: 22%;
	left: 22%;
	border-radius: 50%;
}
.PostVehicle button {
	font-weight: 500;
	padding: 10px 29px;
}
.postSnap form .form-control {
	background: #f5f5f5;
}
.postSnap .kiHXWb {
	background-color: #f5f5f5;
}
.postSnap h6 {
	color: #1e1e1e;
	font-family: "Montserrat";
	font-size: 18px;
	font-weight: 600;
}
.postSnap .form-check .form-check-input {
	border-radius: 50%;
}
.postSnap .form-check-input:checked {
	background: #43f22c;
	border: 1px solid #43f22c;
	box-shadow: none;
}
.postSnap .form-check-input[type="checkbox"]:checked::after {
	content: "\f00c";
	font-family: "FontAwesome";
	position: relative;
	top: -6px;
	font-size: 12px;
	left: 1px;
}
