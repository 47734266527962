.profile-messsage-sec {
  padding: 40px 0px;
}
.profile-messsage-sec .name-and-btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.profile-messsage-sec .name-and-btn-wrapper button.btn {
  background-color: #43f22c;
  padding: 10px 30px;
  color: #000000;
  font-family: "Montserrat";
  font-weight: 700;
  transition: 1s;
  border: 1px solid #43f22c;
  text-decoration: unset;
}
.profile-messsage-sec .name-and-btn-wrapper h5 {
  color: #393939;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 19px;
}

.profile-messsage-sec .name-and-btn-wrapper h4 {
  color: #393939;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 24px;
}
.des-wrapper p {
  color: #959595;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
}
.des-wrapper h6 {
  color: #434344;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 15px;
}
.des-wrapper h6 span {
  color: #006fff;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
}
.profile-img-wrapper img {
  height: 255px;
  width: 255px;
  object-fit: cover;
  border-radius: 50%;
}
