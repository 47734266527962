.cardetail-sec {
	padding: 100px 0px 50px 0px;
}
.cardetail-sec .detail-img-wrapper span.no {
	font-size: 32px;
	font-family: "Montserrat";
	font-weight: 500;
	color: #43f22c;
}
.cardetail-sec .img-and-btn-wrapper .detail-img-wrapper {
	position: relative;
}
.cardetail-sec .detail-img-wrapper span.no {
	position: absolute;
	bottom: 2.5%;
	right: 15%;
}
.cardetail-sec .btn-wrapper {
	position: absolute;
	right: 10%;
	bottom: 6%;
}
.cardetail-sec .img-and-btn-wrapper button.slick-arrow.slick-prev {
	display: none !important;
}
.cardetail-sec .img-and-btn-wrapper button.slick-arrow.slick-next {
	position: absolute;
	top: 92%;
	transform: translate(110%, -50%);
	background: #43f22c;
	width: 32px;
	height: 34px;
}
.cardetail-sec .img-and-btn-wrapper .slick-next:before {
	font-size: 15px !important;
}
.top-btn-wrapper button.btn {
	font-weight: 700;
	font-size: 11px;
	padding: 7px 14px;
}
.top-btn-wrapper button.btnBlack {
	font-size: 11px;
	font-family: "Montserrat";
	background-color: #212121;
	color: #ffffff;
	padding: 7px 14px;
	border: 1px solid #212121;
	transition: 1s;
	transition: 1;
	text-decoration: unset;
	margin-top: 5px;
	font-weight: 700;
	border-radius: 0.25rem;
	margin-top: 0px;
}
.top-btn-wrapper button.btngrey {
	font-size: 11px;
	font-family: "Montserrat";
	background-color: #f5f5f5;
	color: #505050;
	padding: 7px 14px;
	border: 1px solid #f5f5f5;
	transition: 1s;
	transition: 1;
	text-decoration: unset;
	margin-top: 5px;
	font-weight: 700;
	border-radius: 0.25rem;
	margin-top: 0px;
}
.top-btn-wrapper button {
	margin: 0px 3px;
}
.detail-content-wrapper .top-btn-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.top-btn-wrapper span.price {
	display: block;
}
.detail-content-wrapper .top-btn-wrapper h4 {
	font-family: "Montserrat";
	font-size: 14px;
	font-weight: 700;
}
.detail-content-wrapper .top-btn-wrapper h4 span.price {
	color: #43f22c;
	font-family: "Montserrat";
	font-size: 30px;
}
.title-name-wrapper h2 {
	font-family: "Montserrat";
	font-size: 26px;
	font-weight: 700;
	color: #2a2a2a;
}
.title-name-wrapper {
	margin: 10px 0px;
}
.cardetail-sec .detail-content-wrapper h5 {
	color: #000000;
	font-family: "Montserrat";
	font-size: 14px;
}
.cardetail-sec .detail-content-wrapper h5 i {
	color: #b1b1b1;
}
.detail-content-wrapper .stats-wrapper {
	display: flex;
}
.detail-content-wrapper .stats-wrapper h5 {
	margin: 0px 12px 0px 0px;
	color: #b9b9b9;
	font-family: "Montserrat";
	font-size: 22px;
	font-weight: 700;
}
.detail-content-wrapper .stats-wrapper h5 span {
	display: block;
	color: #000000;
	font-size: 14px;
	font-weight: 500;
}
.cardetail-sec .highlights-wrapper {
	margin: 16px 0px;
}
.detail-content-wrapper .highlights-wrapper h4 {
	color: #2a2a2a;
	font-family: "Montserrat";
	font-size: 22px;
	font-weight: 700;
}
.detail-content-wrapper .highlights-list-wrapper ul {
	padding: 0px;
	column-count: 2;
	list-style: none;
}
.detail-content-wrapper .highlights-list-wrapper ul li {
	font-size: 13px;
	color: #aeaeae;
	font-family: "Poppins";
	line-height: 2;
}
.detail-content-wrapper .highlights-list-wrapper ul li i {
	background: #000000;
	padding: 2px;
	border-radius: 50%;
	font-size: 7px;
	color: #fff;
	font-weight: 400 !important;
	margin-right: 8px;
}
.cardetail-sec .seller-snap-wrapper p {
	color: #b9b9b9;
	font-size: 11px;
	font-family: "Montserrat";
}
.cardetail-sec .seller-snap-wrapper {
	background: #d6fcd1;
	padding: 17px;
}
.cardetail-sec .seller-heading-wrapper h5 {
	color: #2a2a2a;
	font-family: "Montserrat";
	font-size: 22px;
	font-weight: 700;
}
.car-specification .specs-wrapper h6 {
	color: #2a2a2a;
	font-family: "Montserrat";
	font-size: 18px;
	font-weight: 700;
}
.specs-wrapper ul li span.property {
	color: #aeaeae;
	font-family: "Poppins";
	font-weight: 400;
}
.specs-wrapper ul {
	list-style: none;
	padding: 0px;
	padding-top: 17px;
}
.specs-wrapper ul li {
	display: flex;
	justify-content: space-between;
}
.specs-wrapper ul li span.value {
	font-size: 14px;
	color: #4d4d4d;
	font-family: "Poppins";
	font-weight: 500;
}
.equip-list-wrapper ul {
	padding: 0px;
	list-style: none;
	column-count: 4;
}
.equip-list-wrapper ul li {
	font-family: "Poppins";
	color: #9a9a9a;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.8;
}
.equip-list-wrapper {
	padding-top: 19px;
}
.EQUIPMENT-sec {
	padding: 30px 0px;
	background: #f5f5f5;
	margin-top: 30px;
}
.EQUIPMENT-sec .equipment-heading-wrapper h5 {
	color: #2a2a2a;
	font-family: "Montserrat";
	font-size: 22px;
	font-weight: 700;
}
.feature-sec .checkup button.btn {
	font-weight: 600;
	font-size: 14px;
}
.BuyerTools {
	padding: 50px 0;
}
.BuyerTools h4 {
	font-family: "Montserrat";
	font-size: 27px;
	font-weight: 700;
	color: #2a2a2a;
}
.buyer-card-wrapper .buyer-content-wrapper h5 {
	font-family: "Montserrat";
	font-size: 18px;
	font-weight: 700;
	color: #2a2a2a;
}
