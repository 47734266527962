/* Login Css Start Here */
h2 {
	font-family: "Poppins";
}
.login {
	background-image: url(../img/login-back.png);
	background-size: cover;
	background-repeat: no-repeat;
	padding: 100px 0 250px;
}
.verification {
	background-image: url(../img/veri.png);
	background-position: bottom;
}
.login .login_box {
	background: #fff;
	padding: 40px 40px 70px;
	border-radius: 10px;
	box-shadow: 0px 0px 10px #ddd;
}
.login .login_box .heading {
	text-align: center;
	padding-bottom: 25px;
}
.login .login_box .heading h2 {
	font-size: 32px;
	color: #1e1e1e;
	font-family: "Poppins";
	font-weight: 700;
	text-transform: uppercase;
}
.login .login_box .registertxt h2 {
	font-family: "monument-regular";
}
.change-pass .heading h2 {
	text-transform: capitalize !important;
	font-weight: 700 !important;
}
.login .login_box .heading p {
	color: #808080;
	font-size: 16px;
	font-family: "Poppins";
	font-weight: 300;
}
.login .login_box .heading p a {
	color: #000000;
	font-weight: 700;
}
.login .login_box .form-group .form-control {
	background: #f1f1f1;
	border-radius: 0;
	border: 0;
	padding: 14px 40px;
	font-family: "poppins";
	width: 100% !important;
}
.login .login_box .rememberDv {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.login .login_box .rememberDv .form-control {
	width: unset;
}
.login .login_box .rememberDv .form-group {
	display: flex;
	align-items: center;
	margin: 0 0 0 20px;
}
.login .login_box .rememberDv .form-group label {
	margin: 0;
	font-family: "Montserrat";
	color: #808080;
	margin-left: 2px;
}
.login .login_box .rememberDv .form-check-input[type="checkbox"] {
	border: 2px solid #acacac;
	margin-top: 0.3px;
	box-shadow: none;
}
.login .login_box .rememberDv a {
	color: #808080;
	font-family: "Montserrat";
}
.login .login_box .button-group {
	margin-top: 30px;
}
.login .login_box button {
	width: 100%;
	background: #44f92c;
	border: 0;
	padding: 13px 0;
	border-radius: 5px;
	font-family: "Montserrat";
	font-weight: 600;
	border-radius: 0;
}
.login .login_box form {
	text-align: center;
}
.login .login_box form label.title {
	font-size: 20px;
	font-family: "Poppins";
	color: #000;
	font-weight: 500;
	padding: 0 0 20px;
}
.login .login_box form > div {
	justify-content: center;
}
.login .login_box form > div > input {
	background: #f5f5f5;
	border: 0;
	width: 60px !important;
	height: 60px !important;
	border-radius: 0;
	box-shadow: none;
	outline: none;
}
.register .heading h2 {
	text-transform: capitalize !important;
	padding-bottom: 15px;
}
.register .heading h2 {
	text-transform: capitalize !important;
	padding-bottom: 15px;
}
.register .checkk {
	margin-left: 20px;
}
.register .checkk label {
	text-align: left;
	font-size: 14px;
	color: #a5a5a5;
	font-family: "Montserrat";
	margin-left: 10px;
}
.register {
	background-image: url("../img/register.png");
	background-size: cover;
	background-repeat: no-repeat;
}
.register .login_box input.form-control::placeholder {
	font-family: "Montserrat";
	font-size: 14px;
}
.register .login_box button {
	color: #000000b5;
	border-radius: 5px;
}
/* Login Css End Here */
