@import url("https://fonts.cdnfonts.com/css/monument-extended");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titan+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

body {
	overflow-x: hidden;
}

@font-face {
	font-family: "monument-bold";
	src: url(../Fonts/MonumentExtended-Ultrabold.otf);
}

@font-face {
	font-family: "monument-regular";
	src: url(../Fonts/MonumentExtended-Regular.otf);
}

@font-face {
	font-family: "GT Walsheim";
	src: url(../Fonts/GT\ Walsheim\ Pro\ Regular\ Regular.ttf);
}

/* Begin of Slider  */

/* Actions Button Start Here */
.actionLink {
	position: fixed;
	right: 0;
	top: 25%;
	z-index: 2;
}
.actionLink .contactDv {
	display: flex;
	align-items: end;
	transform: translateX(100%);
	transition: 0.6s;
	position: relative;
}
.actionLink .contactDv:hover {
	transform: translateY(-60px);
}
.actionLink .contactDv .contact-btn {
	transform: rotate(-90deg);
	background: #43f22c;
	height: 75px;
	width: 210px;
	text-align: center;
	line-height: 60px;
	color: #515151;
	font-weight: 700;
	font-size: 16px;
	margin-bottom: 50px;
	border-radius: 15px 15px 0 0px;
	cursor: pointer;
	position: absolute;
	right: 140px;
	bottom: 93px;
	transition: 0.6s;
}
.actionLink .contactDv:hover .contact-btn {
	width: 155px;
	height: 60px;
	bottom: 0px;
}
.actionLink .contactDv.newsletter .contact-btn {
	width: 130px;
	right: 80%;
	background: #000;
	color: #fff;
	top: 0;
}
.actionLink .contactDv:hover .contact-btn {
	bottom: 0;
	width: 150px;
	right: 79%;
}
.actionLink .contactDv.newsletter .contact-btn {
	top: -30px;
}
.actionLink .contactDv.newsletter:hover .contact-btn {
	width: 100px;
	font-size: 13px;
	right: 90%;
	top: 80px;
}
.actionLink .contactDv .chatDv {
	background: #fbfbfb;
	padding: 20px 20px;
	width: 220px;
	border-radius: 5px;
}
/* .contactDv.newsletter .chatDv {
  width: 300px;
} */
.actionLink .contactDv .chatDv h3 {
	color: #0c0c0c;
	font-size: 15px;
	font-family: "Montserrat";
	font-weight: 600;
	margin-bottom: 10px;
}
.actionLink .contactDv .chatDv .form-control {
	border-radius: 0;
	color: #959292;
	font-family: "Montserrat";
	font-size: 10px;
	background: transparent;
	border: 0;
	border-bottom: 1px solid #707070c2;
	padding: 9px 0;
	overflow: unset;
	box-shadow: none;
}
.actionLink .contactDv .chatDv textarea {
	height: 70px;
	resize: none;
}
.actionLink .contactDv .chatDv button {
	background: #43f22c;
	border: 0;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 12px;
	width: 100%;
	padding: 9px 0;
	border-radius: 5px;
}
.contactDv.newsletter .chatDv button {
	width: auto;
	padding: 10px 30px;
	border-radius: 0;
}
.chat-now {
	display: flex;
	align-items: center;
	cursor: pointer;
	bottom: unset;
	right: 5%;
	z-index: 3;
	position: absolute;
	margin-top: -90px;
}
.chat-now label {
	margin-right: 15px;
	margin-bottom: 0;
	font-family: "Montserrat";
	font-weight: 700;
	color: #515151;
}
.chat-now figure {
	margin: 0;
}
/* Actions Button End Here */

.john-doe h4 {
	font-size: 20px;
	color: #333333;
	font-family: "Roboto", sans-serif;
}
/* .Header .row {
    display: flex !important;
    align-items: end !important;
} */

.header-logo img {
	width: 42%;
}
.slides.for1 {
	background-color: #f5f5f5;
}

.slider-input {
	text-align: center;
	position: relative;
	flex: 1;
}

.search-icon img {
	/* background-color: #43f22c; */
	/* padding: 13px 14px; */
	width: 46px !important;
	border-radius: 35px;
	cursor: pointer;
	transition: 0.6s;
}
/* .search-icon:hover i {
	background-color: #17750a;
	color: #fff;
} */
.slider-all .searchBox input::placeholder {
	font-family: "Montserrat";
	font-weight: 400;
	color: #757575;
}
/* .search-icon {
    position: absolute;
    top: 0px;
    right: 0;
    left: 490px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
} */
.slider-all .searchBox {
	display: flex;
	align-items: center;
	width: 80%;
	position: relative;
	justify-content: center;
	margin: 0px auto;
}
.slider-all .searchBox .search-icon {
	position: absolute;
	right: 5px;
}
.slider-input input {
	position: relative;
	width: 100%;
	padding: 12px 30px;
	border: none;
	border-radius: 60px;
}
.slider img {
	width: 100%;
	position: relative;
}

.slider {
	position: relative;
}

.testi h2 {
	font-size: 27px;
	color: #2a2a2a;
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
	padding: 40px;
}
.testi {
	text-align: center;
}

.for-flexing {
	display: flex;
	align-items: center;
	/* background-color: white; */
}
.HowItWorks .sliderx-img img {
	height: 331px;
	object-fit: cover;
}
.slides.for1 {
	background-color: white;
}
.slider-para {
	display: flex;
	flex-direction: row;
	background-color: #cbfbc4;
}
.slider-para p {
	color: #a4a4a4;
	font-family: Montserrat, sans-serif;
	font-size: 12px;
	font-weight: 400;
	padding-left: 42px;
	padding-top: 80px;
}
.slider-all {
	position: absolute;
	top: 200px;
	right: 0;
	left: 0;
	align-items: center;
	justify-content: center;
}

.slide-text h2 {
	margin: 0;
	font-size: 90px;
	color: #ffffff;
	font-family: "Roboto", sans-serif;
}

.slide-text p {
	font-size: 15px;
	color: #ffffff;
	font-family: "Roboto", sans-serif;
	margin: 0;
}
.slides.for1 {
	background-color: #f5f5f5;
	padding: 0px 110px;
	padding-bottom: 70px;
}
.slide-text h2 span {
	color: #232323;
}

.slide-btn a {
	background-color: #232323;
	padding: 7px 58px;
	color: #ffffff;
	text-decoration: unset;
	border: 1px solid #aeaeae;
	line-height: 90px;
}

.slide-text h5 {
	font-size: 12px;
	color: #ffffff;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	margin: 0;
	position: relative;
	left: 60px;
}

.slide-text h5::before {
	content: "";
	background-color: #232323;
	width: 6%;
	height: 3px;
	position: absolute;
	top: 6px;
	left: -59px;
}

section.about-sec {
	background-image: url("../img/pic2.png");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	width: 100%;
	padding: 70px 0px;
	margin-top: -6px;
}

.slidz-img {
	align-items: center;
	background-color: #8a8a8a;
	display: flex;
	height: 65px;
	justify-content: center;
	left: 0;
	top: -23px;
	position: absolute;
	width: 85px !important;
}

.slidz-img2 {
	align-items: center;
	/* color: #43f82b !important; */
	display: flex;
	height: 65px;
	justify-content: center;
	right: 10px;
	bottom: 80px;
	position: absolute;
	width: 85px !important;
}

.slidz-img2 img {
	object-fit: contain;
	height: 47px !important;
	width: 48px !important;
}

.HowItWorks .slidz-img img {
	object-fit: contain;
	height: 47px;
	width: 35px;
}
.john-doe img {
	width: 20% !important;
}

.slick-prev:before {
	content: "\f053" !important;
	font-family: "FontAwesome" !important;
	color: #000 !important;
}
.slick-next:before {
	content: "\f054" !important;
	font-family: "FontAwesome" !important;
	color: #373737 !important;
}

.HowItWorks {
	padding-top: 70px;
	background: #f5f5f5;
}

.slick-prev {
	left: 25px !important;
	z-index: 9999999 !important;
}

.slick-next {
	right: 35px !important;
}

.slick-prev:before,
.slick-next:before {
	font-size: 35px !important;
}

.sell-btn a:hover {
	background-color: transparent;
	border: 1px solid #43f22c;
	color: #43f22c;
}
/* End of Slider  */

.header-list ul li ul.dropdown-inner {
	position: absolute;
	top: 0;
	left: 0;
	padding: 0;
	display: block !important;
}
.header-list ul li ul.dropdown-inner li {
	display: block !important;
	padding: 0;
}

/* Begin of about section  */
.abt-img img {
	width: 100%;
}

.abt-text h3 {
	font-size: 47px;
	color: #ffffff;
	font-family: "Roboto";
	font-weight: 400;
}

.abt-all h4 {
	font-size: 20px;
	color: #ffffff;
	font-family: "Nunito", sans-serif;
	font-weight: 400;
	padding: 20px 0px;
}
.abt-all p {
	font-size: 13px;
	color: #ffffff;
	font-family: "Roboto";
	font-weight: 400;
}

.about-head h2 {
	font-size: 48px;
	color: #ffffff;
	font-family: "Lato", sans-serif;
	font-weight: 700;
}

.about-head {
	text-align: center;
	padding: 40px 0px;
}

.for-flex {
	display: flex;
	align-items: center;
}
/* End of about section  */

/* Begin of service section  */
.service-img img {
	width: 100%;
}

.service-text h3 {
	font-size: 35px;
	color: #a8741a;
	font-family: "Nunito";
	font-weight: 700;
	position: relative;
	left: 70px;
}

.service-text p {
	font-size: 13px;
	color: #ffffff;
	font-family: "Roboto";
	font-weight: 300;
}

section.service-sec .row {
	display: flex;
	align-items: center;
}

.service-head h2 {
	font-size: 48px;
	color: #ffffff;
	font-family: "Roboto";
	font-weight: 500;
}

.service-text h3::before {
	content: "";
	width: 12%;
	height: 4px;
	background-color: #ffffff;
	position: absolute;
	left: -69px;
	top: 17px;
}
section.service-sec {
	background-image: url("../img/pic3.png");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	width: 100%;
	padding: 80px 0px;
	margin-top: 0px;
}

.service-head {
	text-align: center;
	padding: 40px;
}

.service-text a {
	font-size: 13px;
	color: #ffffff;
	text-decoration: underline;
}

.for-allign {
	text-align: end;
}

.for-allign h3 {
	position: relative;
	left: 0;
}

.for-allign h3::before {
	content: "";
	width: 12%;
	height: 4px;
	background-color: #ffffff;
	position: absolute;
	left: 249px;
	top: 17px;
}
/* .slick-slider .slick-center{
    background-color: red;
    padding: 50px;
    color: blue;
    margin: 0 1.5rem;
}


.slick-slider .slick-current{background-color: red;
    padding: 50px;
    color: blue;
 
} */

.HowItWorks img {
	width: 100%;
}

/* .how-sec .slides img {
    width: 50%;
    margin: 0 auto;
}

.how-sec .slick-slide.slick-active.slick-center.slick-current {
    transform: scale(1.7);
} */
/* End of service section  */

/* Begin of Header section  */
.header-list ul {
	display: flex;
	list-style: none;
}

/* .Header {
    position: absolute;
    width: 100%;
    z-index: 999999;
    padding: 20px 0px;
    top: 0;
} */

.header-list ul li {
	font-size: 13px;
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
	color: #000;
	padding: 5px 15px;
	position: relative;
}

.header-list a.login-btn {
	background-color: #43f22c;
	padding: 12px 20px;
	color: #000000;
	font-family: "Montserrat";
	font-weight: 700;
	transition: 1s;
	border: 1px solid #43f22c;
	text-decoration: unset;
	border-radius: 5px;
}

.header-list {
	float: right;
}

.login-btn a {
	background-color: #232323;
	padding: 10px 47px;
	border: 1px solid #aeaeae;
	color: #ffffff;
}

.login-btn {
	text-align: right;
}

.login-btn img {
	padding-right: 19px;
}

.Header .row {
	display: flex;
	align-items: end;
}

/* End of Header section  */

/* Begin of Car section  */
.car-dv img {
	width: 100%;
}

.for-car1:before {
	content: "01";
	font-size: 112px;
	font-family: monument-regular;
	position: absolute;
	z-index: -1;
	top: -2px;
	left: 44px;
	color: #43f22c3b;
}
.john-doe h5 {
	font-size: 12px;
	color: #33333369;
	font-family: "Roboto";
}

.for-car2:before {
	content: "02";
	font-size: 102px;
	font-weight: 700;
	font-family: monument-regular;
	position: absolute;
	z-index: -1;
	top: -2px;
	left: 52px;
	color: #43f22c3b;
}

.for-car3:before {
	content: "03";
	font-size: 102px;
	font-weight: 700;
	font-family: monument-regular;
	position: absolute;
	z-index: -1;
	top: -5px;
	left: 55px;
	color: #43f22c3b;
}

.for-car4:before {
	content: "04";
	font-size: 102px;
	font-weight: 700;
	font-family: monument-regular;
	position: absolute;
	z-index: -1;
	top: 0px;
	left: 55px;
	color: #43f22c3b;
}
.car-dv {
	box-shadow: 0px 0px 11px #70707042;
	height: 170px;
	padding: 65px 45px 45px;
	border-radius: 10px;
	transition: 0.6s;
}
.tesla-model h5 {
	font-size: 17px;
	color: #707070;
	font-family: "Montserrat";
	font-weight: 700;
}
.tesla-model {
	padding-top: 20px;
}

.car-head h3 {
	font-size: 24px;
	color: #444444;
	font-family: "Poppins";
	font-weight: 700 !important;
	padding-bottom: 25px;
	letter-spacing: 0px;
}
section.car-sec {
	padding-top: 50px;
}

/* End of Car section  */

/* Feature section  */
.feature-head p {
	font-size: 9px;
	color: #2a2a2a;
	font-weight: 600;
	background-color: #43f22c;
	margin: 0;
	padding: 2px 7px;
	font-family: "Montserrat";
}

.feature-normal-head h3 {
	font-size: 13px;
	color: #929292;
	font-family: "Montserrat";
	font-weight: 600;
	padding-bottom: 18px;
	line-height: 0px;
}

.feature-head h2 span {
	font-size: 13px;
	color: #2a2a2a;
	font-family: "Montserrat";
	position: relative;
	bottom: 5px;
}
.feature-head h2 {
	font-size: 22px;
	color: #2a2a2a;
	font-family: "Montserrat";
	font-weight: 700;
}
.feature-head {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 10px 0px;
	padding-bottom: 0;
}
.feature-icon-head span {
	font-size: 12px;
	color: #b2b2b2;
	font-family: "Montserrat";
	font-weight: 600;
	padding-right: 22px;
}
.feature-iiimmgg img {
	background-color: #fff;
	box-shadow: 0px 0px 11px #70707042;
	border-radius: 40px;
	height: 19px;
	width: 19px;
	object-fit: none;
}

.feature-iiimmgg span {
	font-size: 12px;
	color: #b2b2b2;
	font-family: "Montserrat";
	font-weight: 600;
}
.feature-icon-head span img {
	background-color: #fff;
	box-shadow: 0px 0px 11px #70707042;
	border-radius: 40px;
	height: 19px;
	width: 19px;
	object-fit: none;
}
.feature-iiimmgg {
	padding: 10px 0px;
}
.btn {
	font-size: 14px;
	font-family: "Montserrat";
	background-color: #43f22c;
	color: #000000;
	padding: 8px 35px;
	/* line-height: 80px; */
	border: 1px solid #43f22c;
	transition: 1s;
	transition: 1;
	text-decoration: unset;
	margin-top: 5px;
}

.sellbtn {
	font-weight: 600;
	padding: 10px 40px !important;
}

.featured-all img {
	position: relative;
}

.new-listing h5 {
	font-size: 8px;
	color: #2a2a2a;
	background-color: white;
	padding: 6px 14px;
	font-family: "Montserrat";
	font-weight: 600 !important;
	text-transform: uppercase;
}

.new-listing {
	position: absolute;
	top: 6px;
	left: 24px;
}

.f-img img {
	width: 100%;
	height: 143px;
}
section.feature-sec {
	padding: 60px;
}

.upper-padding {
	padding-top: 70px;
}

.TestimonialBtn {
	font-size: 20px;
	font-family: "monument-regular";
	font-weight: 900;
	color: #444444;
}

.see-all a {
	font-size: 15px;
	color: #000000;
	font-family: "Montserrat";
	background-color: #43f22c;
	padding: 10px 40px;
	font-weight: 600;
}

.see-all {
	text-align: center;
	padding-top: 50px;
	padding-bottom: 20px;
}

.btn:hover {
	background-color: transparent;
	border: 1px solid #43f22c;
	color: #43f22c;
}
/* End of feature seciton  */

/* Begin of Sell section  */
section.sell-sec {
	background-image: url(../img/sell.png);
	background-repeat: no-repeat;
	width: 100%;
	background-size: 100% 100%;
	padding: 100px 0px;
}

.sell-head h3 {
	font-size: 39px;
	color: #ffffff;
	font-family: monument-regular;
	margin: 0;
}

.sell-head p {
	font-size: 16px;
	color: #ffffff;
	font-weight: 300;
	font-family: "Montserrat";
	padding-bottom: 40px;
}
.sell-head h2 {
	font-size: 83px;
	color: #ffffff;
	font-family: monument-regular;
	margin: 0;
	padding-bottom: 0px;
	padding-top: 9px;
}

.subBtn {
	font-size: 12px;
	color: #fff;
	font-family: "Montserrat";
	/* font-weight: 700; */
	background-color: #000000 !important;
	padding: 7px 50px;
	transition: 1s;
	border-radius: 5px;
	text-decoration: unset;
	cursor: pointer;
}
/* End of Sell section  */

/* Begin of Footer  */
.copy-right {
	background-color: #44f92c;
	text-align: center;
	margin-top: 70px;
}
.copy-right p {
	font-size: 16px;
	color: #373737;
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	margin: 0;
	padding: 22px;
}
.footer-head ul li {
	font-size: 12px;
	color: #1d1d1d;
	font-family: "Montserrat";
	font-weight: lin;
	line-height: 34px;
}
footer .widget3 {
	margin: 0px auto;
	display: table;
}
.footer-head ul li a {
	color: #1d1d1d;
	font-weight: 500;
	font-size: 14px;
}

.footer-head ul {
	list-style: none;
	padding: 0px 6px;
}

.footer-head {
	padding-top: 28px;
}

.footer-para p {
	font-size: 12px;
	color: #1d1d1d;
	font-family: "Montserrat";
	font-weight: 500;
}

.footer-social-item ul {
	display: flex;
	list-style: none;
	padding: 0;
}

/* Contact page Start */
.contactSection {
	display: flex;
	flex-direction: row;
	text-align: center !important;
	margin-top: 5px;
}
.contactSection2 {
	display: flex;
	flex-direction: row;
	text-align: center !important;
}
.contactSection3 {
	display: flex;
	flex-direction: row;
	text-align: center !important;
}

.contactSection span:first-child {
	margin-top: -4px;
}
.contactSection2 span:first-child {
	margin-top: -4px;
}
.contactSection3 span:first-child {
	margin-top: -4px;
}

.contactSection p {
	font-size: 14px;
	margin-left: 5px !important;
}
.contactSection2 p {
	font-size: 14px;
	margin-left: 5px !important;
}
.contactSection3 p {
	font-size: 14px;
	margin-left: 5px !important;
}

.iconzz {
	width: 20px !important;
	height: 30px !important;
}

.contact {
	background-image: url("../img/Contact_back.png");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	padding: 100px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.contact button {
	font-family: "poppins";
	font-weight: 500;
}
.Contact_us_head {
	font-size: 18px;
	color: #ffffff;
	font-family: monument-regular;
	margin-bottom: 50px;
}
.first_select::placeholder {
	color: rgba(255, 255, 255, 0.781);
	font-size: 13px;
}
.first_select {
	background-color: #202123;
	color: #ffffff;
	border: none;
	margin-bottom: 10px;
	padding: 15px;
	font-family: "GT Walsheim";
}

.first_select:focus {
	outline: none !important;
}

.select_box11 .first_select {
	background-color: #202123;
	color: #ffffff;
	border: none;
	margin-bottom: 10px;
	padding: 10px;
	background-image: url(../img/arrow-down.png);
	background-position-x: 95%;
	background-position-y: center;
	background-repeat: no-repeat;
	height: calc(3.5rem + 2px) !important;
	line-height: 1.5;
}

/* .Icon_set_contact {
	position: absolute;
	top: 35px;
	right: -5px;
} */

.first_select:focus {
	outline: none;
	background-color: #202123;
	color: #fff;
}

/* .first_contant_contact .form-control::placeholder {
	padding-left: 8px;
} */

.first_contant_contact,
.second_contant_contact {
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.rc-anchor-light {
	background: #202123 !important;
	border: 0 !important;
	color: white !important;
}
/* Contact page End */
.footer-head h3 {
	font-size: 19px;
	color: #1d1d1d;
	font-family: "Montserrat";
	font-weight: 700;
	text-transform: uppercase;
	padding-bottom: 15px;
}
.footer-logo p {
	font-size: 12px;
	color: #1d1d1d;
	font-family: "Montserrat";
	font-weight: 500;
}

.footer-social-item ul li a {
	background-color: #44f92c;
	padding: 8px 10px;
	color: #ffffff;
	margin-right: 9px;
	width: 44px;
	display: block;
	text-align: center;
	height: 40px;
	border-radius: 5px;
}
.footer-social-item ul li a svg {
	fill: #fff;
	width: 17px;
}
.footer-social-item h3 {
	font-size: 13px;
	color: #1d1d1d;
	font-family: "Montserrat";
	font-weight: 700;
	padding-bottom: 7px;
	padding-top: 7px;
}
footer {
	padding-top: 30px;
}
footer .widget4 {
	margin: 0px 0 0 auto;
	display: table;
}
.footer-input input {
	padding: 10px 30px;
}
.footer-btn .btn {
	background-color: #44f92c;
	border: unset;
	padding: 12px 30px;
	flex: 0 0 30%;
	font-family: "Montserrat";
	/* font-weight: 700; */
	text-transform: uppercase;
	font-size: 12px;
}
/* .footer-input input {
	position: relative;
	border: 1px solid #5d5d5d;
	margin: 0px;
	width: 70%;
	height: 42px;
	padding: 10px;
	flex: 0 0 70%;
} */

.footer-input {
	position: relative;
	display: flex;
	flex-direction: column;
}
.footer-btn .btn {
	position: absolute;
	top: 0;
	right: 9px;
}
.footer-input input::placeholder {
	color: #aaaaaa;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 15px;
}
/* End of Footer  */

/* Responsive section  */
@media (max-width: 1400px) {
	.header-list ul li {
		padding: 7px 16px;
	}

	.car-dv {
		height: 140px;
	}

	.for-car1:before {
		font-size: 82px;
		top: -6px;
		left: 51px;
	}

	.for-car2:before {
		font-size: 78px;
		top: -6px;
		left: 47px;
	}

	.for-car3:before {
		font-size: 78px;
		top: -6px;
		left: 47px;
	}

	.for-car4:before {
		font-size: 78px;
		top: -6px;
		left: 47px;
	}

	.feature-head h2 {
		font-size: 18px;
	}
	.feature-head p {
		font-size: 8px;
		white-space: nowrap;
	}
	.feature-icon-head span {
		font-size: 9px;
		padding-right: 13px;
	}

	.feature-iiimmgg span {
		font-size: 9px;
	}

	.new-listing h5 {
		font-size: 8px;
	}
	/* .checkup .btn {
		font-size: 10px;
		line-height: 60px;
	} */
	.see-all a {
		font-size: 12px;
	}
	.sell-head h2 {
		font-size: 74px;
		padding-bottom: 33px;
	}
	.sell-head h3 {
		font-size: 36px;
	}

	.slider-para p {
		font-size: 11px;
	}
	.john-doe h4 {
		font-size: 18px;
	}
	.john-doe h5 {
		font-size: 9px;
	}
	.footer-logo p {
		font-size: 12px;
	}
	.footer-head ul li {
		font-size: 11px;
	}
	.footer-para p {
		font-size: 11px;
	}
	.footer-input input {
		padding: 6px 16px;
		position: relative;
	}
	/* .footer-btn button {
		padding: 8px 30px;
	} */
	.footer-btn {
		right: -23px;
	}
	.copy-right p {
		font-size: 13px;
	}
	.footer-head h3 {
		font-size: 14px;
	}
}
@media (max-width: 768px) {
	.slider-all {
		top: 100px;
	}
	.slider-input input {
		padding: 10px 30px;
	}
	.search-icon {
		top: 1px;
	}
	.for-car1:before {
		font-size: 73px;
		top: 4px;
		left: 31px;
	}
	.for-car2:before {
		font-size: 71px;
		top: 4px;
		left: 31px;
	}
	.for-car3:before {
		font-size: 73px;
		top: 4px;
		left: 31px;
	}
	.for-car4:before {
		font-size: 73px;
		top: 7px;
		left: 31px;
	}
	.feature-head {
		display: block;
		padding: 7px 0px;
	}
	.feature-normal-head h3 {
		font-size: 9px;
		line-height: 12px;
		padding-bottom: 0%;
	}
	.feature-icon-head span {
		font-size: 11px;
		padding-right: 13px;
	}
	/* .checkup .btn {
		font-size: 8px;
	} */
	/* .checkup .btn {
		padding: 5px 15px;
	} */
	.see-all {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	section.feature-sec {
		padding: 20px;
	}

	.feature-head h2 {
		font-size: 12px;
	}
	.feature-head h2 span {
		font-size: 8px;
		bottom: 3px;
	}
	.feature-icon-head span {
		font-size: 6px;
		padding-right: 6px;
	}
	.feature-iiimmgg span {
		font-size: 7px;
	}
	.feature-icon-head span img {
		padding: 6px 5px;
	}
	.feature-iiimmgg img {
		padding: 6px 5px;
	}
	.checkup .btn {
		line-height: 30px;
	}
	.see-all a {
		font-size: 9px;
		padding: 6px 27px;
	}
	.sell-head h3 {
		font-size: 20px;
	}
	.sell-head h2 {
		font-size: 38px;
	}
	.sell-head p {
		font-size: 8px;
	}
	.sell-btn a {
		font-size: 8px;
		padding: 6px 35px;
	}
	section.sell-sec {
		padding: 50px 0px;
	}
	.sell-head p {
		font-size: 16px;
		padding-bottom: 10px;
	}
	.testi h2 {
		font-size: 18px;
		padding: 30px;
	}
	.slider-para p {
		font-size: 7px;
	}
	.john-doe h4 {
		font-size: 11px;
	}
	.john-doe h5 {
		font-size: 6px;
	}
}
@media (max-width: 1100px) {
	.slider-all {
		top: 100px;
	}
}
@media (max-width: 576px) {
	.slider-all {
		top: 60px !important;
	}
	.car-dv {
		height: 190px;
	}
	.slides.for1 {
		padding: 0px 18px;
	}
	.header-list ul li {
		font-size: 8px;
	}
	.feature-icon-head span {
		font-size: 16px;
	}
	.feature-iiimmgg span {
		font-size: 16px;
	}
	.sell-head p {
		font-size: 10px;
		padding-bottom: 10px;
	}
	section.sell-sec {
		padding: 36px 0px;
	}
	.slider-all {
		top: 37px;
	}
	.slider-input input {
		width: 84%;
		padding: 4px 19px;
	}
	.slider-para p {
		padding-top: 20px;
	}
	.slider-all input::placeholder {
		font-size: 10px;
	}
	/* .search-icon {
        left: 267px;
    } */
	.search-icon i {
		padding: 7px 7px;
	}
}
/* End of Responsive section  */

.header-list .login-btn:hover {
	background-color: transparent;
	border: 1px solid #43f22c;
	color: #43f22c;
}
.header-list ul li a {
	color: #606060;
	text-decoration: none;
	text-transform: capitalize;
}
.header-list ul li span {
	display: block;
}

.header-list ul li span img {
	float: right;
	position: relative;
	right: 9px;
}

.feature-add-img img {
	width: 100%;
}
.feature-add-img img {
	height: 350px;
}
.car-dv:hover {
	filter: brightness(0.5);
	cursor: pointer;
}

.featured-all:hover .f-img {
	/* transform: scale(1.1);	 */
	cursor: pointer;
	filter: brightness(0.5);
}
.featured-all .f-img {
	transition: 0.6s;
	position: relative;
	box-shadow: 0px 0px 8px #ddd;
}
.featured-all .f-img .actions {
	position: absolute;
	top: 2px;
	right: 10px;
}
.featured-all .f-img .actions a {
	color: #43f22c;
	margin-left: 10px;
}
.header-list ul li ul.dropdown-inner {
	position: absolute;
	padding: 0;
	display: block !important;
	background: #fff;
	top: 40px;
	z-index: 9;
	width: 120px;
}
.header-list ul li ul.dropdown-inner li {
	display: block !important;
	padding: 0;
}
.header-list ul li ul.dropdown-inner li {
	padding: 5px 20px;
}
.header-list ul li ul.dropdown-inner {
	position: absolute;
	padding: 0;
	display: none !important;
	background: #fff;
	top: 30px;
	z-index: 9;
	width: 120px;
	transition: 0.6s;
}
.header-list ul li ul.dropdown-inner li {
	display: block !important;
	padding: 0;
}
.header-list ul li ul.dropdown-inner li {
	padding: 5px 20px;
}
.header-list ul li:hover ul.dropdown-inner {
	display: block !important;
}
