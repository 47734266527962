.about-banner {
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: url("../img/about-banner.png");
	background-size: cover;
	background-repeat: no-repeat;
}
.about-banner .about-banner-heading h1 {
	color: #fff;
	font-family: "monument-regular";
}
.about-content-sec {
	padding: 80px 0px;
}
.about-content-sec p {
	font-family: "Poppins";
	font-size: 21px;
	font-weight: 500;
	color: #040d14;
	text-align: center;
}
.vision-sec {
	padding: 100px 0px;
	background: url("../img/vision-bg.png");
	background-size: cover;
	background-repeat: no-repeat;
}
.vision-sec .vision-card-wrapper {
	padding: 39px;
	background: #ffffff4d;
	-webkit-backdrop-filter: blur(53px);
	backdrop-filter: blur(15px);
}
.vision-sec .vision-card-wrapper h2 {
	font-family: "monument-regular";
	font-size: 42px;
	color: #fff;
	padding-bottom: 5px;
}
.vision-sec .vision-card-wrapper p {
	font-size: 15px;
	color: #fff;
	font-family: "poppins";
}
.mission {
	padding: 100px 0px;
	padding-top: 200px;
	position: relative;
}
.mission img.dots {
	position: absolute;
	right: 17%;
	top: -53px;
	z-index: -2;
}
.mission figure {
	position: relative;
}
.mission img.fluid {
	position: relative;
	z-index: 2;
}
.mission .mission-line {
	position: absolute;
	top: 39%;
	left: -1%;
}
.mission button.btn {
	background: #44f92c;
	padding: 10px 33px;
	font-family: "Montserrat";
	font-size: 15px;
	font-weight: 600;
	color: #02090f;
}
.mission h2 {
	color: #040d14;
	font-family: "monument-regular";
	font-size: 45px;
	padding-bottom: 5px;
}
.mission p {
	font-family: "Poppins";
	color: #040d14;
	font-size: 15px;
	line-height: 24px;
}
.mission .content-wrapper {
	padding-left: 50px;
}
/* contact starts here */
.contact-us {
	background-image: url("../img/contact-bg.png");
	background-repeat: no-repeat;
	background-size: cover;
	padding: 100px 0px;
}
.contact-form-wrapper h2 {
	text-align: center;
	color: #fff;
	font-family: "monument-regular";
}
.contact-form-wrapper .contact-heading-wrapper {
	padding-bottom: 30px;
}
.contact-form-wrapper h2 {
	text-align: center;
	color: #fff;
	font-family: "monument-regular";
	font-size: 44px;
}
.contact-form-wrapper .contact-heading-wrapper {
	padding-bottom: 30px;
}
.contact-us .form-wrapper .form-control {
	background: #202123;
	border: 1px solid #202123;
	font-family: "Poppins";
	color: #fff;
	box-shadow: none;
	padding: 10px 20px;
	height: 50px !important;
	line-height: 18px;
}
.contact-us textarea {
	min-height: 115px !important;
	resize: none;
}
.contact-us .form-wrapper .btn {
	background: #43f82b !important;
	border: 1px solid #43f82b !important;
	font-family: "Poppins";
	font-weight: 600 !important;
	color: #000002 !important;
}
.contact-us .form-wrapper .form-control::placeholder {
	color: #fff;
	font-size: 11px;
}
.contact-us .form-wrapper select {
	font-size: 11px;
}
.contact-us .rc-anchor-light {
	background: #202123 !important;
	color: #000 !important;
}
.contact-us .rc-anchor-light.rc-anchor-normal {
	border: 1px solid #202123 !important;
}
.contact-us .rc-anchor-checkbox-label {
	color: #fff !important;
}
/* contact ends here */
