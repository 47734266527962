p {
	color: #77838f;
	font-size: 17px;
}
/* blog banner css */
.blog-banner {
	background-image: url("../img/blog2.png");
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	padding: 240px 0px;
}
.blog-banner2 {
	background-image: url("../img/blog2.png");
}
/* blog banner css */
/* blog content css */
.blog a.btn {
	padding: 13px 36px;
	background: #363636;
	color: #fff !important;
	font-weight: 500;
	font-family: "Poppins";
	font-size: 14px;
	border: 0;
}
.blog {
	padding: 60px 0px;
}
.blog h3 {
	font-size: 32px !important;
	font-weight: 400 !important;
	font-family: "poppins";
}
.blog .author {
	display: flex;
}
.blog .author .author-nam {
	margin-left: 26px;
}
.blog ul.icons {
	padding: 0px;
	list-style-type: none;
	display: flex;
	align-items: center;
}
.blog .blog-description {
	font-size: 16px;
	color: #77838f;
	font-family: "Poppins";
}
.blog .author-name {
	font-size: 14px;
	font-weight: 500;
	color: #1e2022;
	line-height: 20px;
}
.blog .publish-date {
	font-size: 14px;
	color: #77838f;
	line-height: 20px;
}
ul.icons a {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #77838f;
}
ul.icons a.ico {
	margin: 0px 8px;
	width: 13px;
	height: 39px;
	/* background: #fbc239; */
	border-radius: 50%;
	font-size: 16px;
	color: #fbc239;
}
.into-row {
	border-top: 1px solid #eeeeee;
	padding: 7px 0px !important;
	border-bottom: 1px solid #eeeeee;
}
.blog blockquote {
	text-align: center;
	font-size: 20px;
	color: #1e2022;
	padding-bottom: 40px;
	font-family: "poppins";
}
.blog p.quotaion-name {
	color: #77838f;
	font-size: 14px;
}
.blog ul li {
	color: #77838f;
	font-family: "Poppins";
	margin-bottom: 20px;
}
.blog .quote-wrapper {
	background: #f4f8ff;
	padding: 20px;
	margin: 20px 0px;
}
h3.blog-heading {
	font-weight: 700 !important;
}
@media (max-width: 576px) {
	.blog .social-icons {
		padding-top: 30px;
	}
	.comment-user-img img {
		width: 211px;
	}
}
/* blog content css */
/* comments sections starts here */
.comments h3.comments {
	font-weight: 400;
	font-size: 32px;
}
span.comments-heading {
	background: #ffeff0;
	padding: 10px 30px;
	border-radius: 35px;
	color: #f14b59;
	font-size: 14px;
	font-weight: 500;
}
.comment-box {
	display: flex;
}
.comment-content-warpper {
	display: flex;
	justify-content: space-between;
}
.comment-content-box {
	margin-left: 27px;
}
.comment-form label {
	text-transform: uppercase;
	font-weight: 600;
}
.comment-content-box .user-name {
	font-size: 14px;
	font-weight: 500;
	color: #1e2022;
	line-height: 20px;
}
.comment-content-box .comment-published-date {
	font-size: 14px;
	color: #77838f;
	line-height: 20px;
}
.comment-box a.comment-reply-btn {
	color: #77838f;
	font-size: 14px;
}
.comment-box a.comment-reply-btn i {
	margin-right: 15px;
}
.comment-box {
	display: flex;
	padding: 20px 0px;
	border-bottom: 1px solid #eeeeee;
}
.replyed-comment {
	width: 96%;
	border: 0px !important;
	margin-left: 40px;
}
.comment-form input {
	padding: 16px;
	border-radius: 0px;
}
.comment-form input::placeholder {
	color: #99a2aa;
	font-size: 14px;
	font-family: "Poppins";
}
.comment-form textarea {
	padding: 16px;
	border-radius: 0px;
}
.comments-post-form h5.comment-heading {
	font-size: 22px;
	font-weight: 400;
	font-family: "Poppins";
	margin-bottom: 30px;
}
.comment-form textarea::placeholder {
	color: #99a2aa;
	font-size: 14px;
	font-family: "Poppins";
}
.form-sub-btn {
	text-align: center;
}
.form-sub-btn .btn {
	color: #fff !important;
}
.comment-form button {
	font-size: 15px;
	font-family: "Poppins";
	font-weight: 600;
}
/* comments sections starts here */
