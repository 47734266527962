/* Faqs Css Start Here */
.bannerSec {
	background-image: url("../img/back-faq.png");
	background-repeat: no-repeat;
	background-size: cover;
	padding: 100px 0;
	background-position: "bottom";
}
.bannerSec .content_wrapper {
	max-width: 40%;
}
.bannerSec .content_wrapper h2 {
	padding-bottom: 15px;
	color: #ffffff;
	font-family: "monument-bold";
	line-height: 40px;
	font-size: 30px;
	letter-spacing: 2px;
}
.bannerSec .content_wrapper .searchBox {
	display: flex;
	position: relative;
}
.bannerSec .content_wrapper .searchBox .form-control {
	border-radius: 50px;
	padding: 17px 25px;
	font-family: "montserrat";
	border: 0;
}
.bannerSec .content_wrapper .searchBox button.btn {
	position: absolute;
	right: 6px;
	top: -3px;
	padding: 0;
	background: transparent;
	border: 0;
}
.faqs {
	padding: 80px 0 0px;
}
.faqs .accordion-item .accordion-header {
	background: transparent;
	border-bottom: 1px solid #cfcfcf;
}
.faqs .accordion-item {
	border: 0;
	background: transparent;
}
.faqs .accordion-item .accordion-header button.accordion-button {
	background: transparent;
	outline: none;
	box-shadow: none;
	padding: 15px 0px;
	color: #000;
	font-family: "Montserrat";
}
.faqs .accordion-item .accordion-body {
	padding: 15px 20px 10px;
	font-family: "Montserrat";
	font-size: 15px;
}
.faqs .accordion-item button.accordion-button:after {
	background-image: url("../img/electricity.webp");
}
/* Faqs Css End Here */
