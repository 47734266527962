.auctionWrappper {
	background-color: #f4fff2;
	padding: 40px 0;
}

.heading {
	padding-top: 40px;
}

.sectionHeading {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 2px;
}

.sectionHeading .auctionHead {
	font-family: "Montserrat";
	letter-spacing: 2px;
	font-weight: 700;
	margin: 0;
}

.wraper {
	margin-top: 30px;
}

.firsthead {
	margin-top: 30px;
}

.firstbtn {
	background-color: #5e5e5e;
	color: #fff;
	border: none;
	padding: 8px 8px;
	border-radius: 5px;
}

.secondDiv {
	margin-top: 20px;
}

.secondDiv p {
	font-family: "Montserrat";
	margin: 0;
	font-weight: 700;
	font-size: 14px;
}

.priceNumber {
	font-family: "Montserrat";
	font-weight: 700;
	color: #43f22c;
}

.tablefonts {
	font-family: "Poppins";
	font-size: 16px;
}

.btnColor {
	color: #fff !important;
}

.btnColor:hover {
	background-color: #d4d4d4 !important;
	color: #fff !important;
	border: none !important;
}

.tablefonts tr th {
	width: 25%;
	border: 0;
	color: #333333;
	font-family: "Poppins";
	font-weight: 400;
}

.tablefonts tr td {
	width: 25%;
	border: 0;
	vertical-align: middle;
	border-bottom: 1px solid #ddd;
	padding: 7px 10px;
}

.tablefonts tr td:last-child {
	text-align: end;
}

.tablefonts tr th:last-child {
	text-align: end;
	padding-right: 55px;
}

.tablefonts tr {
}

.tablefonts tbody {
	border: 1px solid #ddd !important;
}

.tablefonts tr td button.btn.btnColor {
	padding: 7px 30px;
}

.Pending {
	font-style: italic;
}
